import React from "react";
import { graphql } from "gatsby";
import classNames from "classnames/bind";
import BaseContentListing from "@v4/gatsby-theme-talend/src/components/modules/contentListing/ContentListing";
import * as listingStyles from "./contentListing.module.css";
import formatContentfulRichText from "@v4/stitch/src/utils/formatContentfulRichText";
import ResourceListing from "../../listings/resources/ResourceListing";
const cx = classNames.bind(listingStyles);

export const fragment = graphql`
    fragment ContentListing on ContentfulContentListing {
        id
        name
        node_locale
        stylingOptions {
            ...stylingOptions
        }
        heading {
            raw
        }
        headingTextShade
        featuredHeading {
            raw
        }
        listContentType
        itemsPerPage
        featuredItems {
            __typename
            ... on Node {
                ...Resource
            }
        }
        tierOneItems {
            __typename
            ... on Node {
                ...Resource
            }
        }
    }
`;

// Map of listing type to listing react component.
const listingTypeMap = {
    Resources: ResourceListing,
};

const ContentListing = (props) => (
    <BaseContentListing
        cx={cx}
        formatContentfulRichText={formatContentfulRichText}
        listingTypeMap={listingTypeMap}
        {...props}
    />
);

export default ContentListing;
