import React from "react";
import BaseCustomerCard from "@v4/gatsby-theme-talend/src/components/modules/multiItem/CustomerCard";
import formatContentfulRichText from "@v4/stitch/src/utils/formatContentfulRichText";
import classNames from "classnames/bind";
import * as cardStyles from "./customerCard.module.css";

const cx = classNames.bind(cardStyles);

const CustomerCard = (props) => (
    <BaseCustomerCard cx={cx} formatContentfulRichText={formatContentfulRichText} {...props} />
);

export default CustomerCard;
