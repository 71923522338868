import React from "react";
import classNames from "classnames/bind";
import { Resource as BaseResource } from "@v4/gatsby-theme-talend/src/components/listings/resources/Resource";
import * as resourceStyles from "./resource.module.css";
const cx = classNames.bind(resourceStyles);

export const Resource = (baseProps) => {
    const props = { ...baseProps };
    props.buttonText = "Read now";
    return <BaseResource cx={cx} {...props} />;
};
