import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import camelcase from "lodash.camelcase";
import Layout from "../../components/layout/Layout";
import Duplex from "../../components/modules/duplex/Duplex";
import Hero from "../../components/modules/hero/Hero";
import FullWidth from "../../components/modules/fullWidth/FullWidth";
import LeftRight from "../../components/modules/leftRight/LeftRight";
import MultiItem from "../../components/modules/multiItem/MultiItem";
import PricingWrapper from "../../components/modules/pricingWrapper/PricingWrapper";
import ContentListing from "../../components/modules/contentListing/ContentListing";
import Seo from "@v4/gatsby-theme-talend/src/components/Seo/Seo";

export const query = graphql`
    query($id: String!, $locale: String!) {
        contentfulPage(id: { eq: $id }) {
            id
            title
            slug
            node_locale
            updatedAt
            createdAt
            metaFields {
                ...meta
            }
            navType
            navTheme
            modules {
                __typename
                ... on Node {
                    ...Duplex
                    ...NotificationBar
                    ...Hero
                    ...FullWidth
                    ...LeftRight
                    ...MultiItem
                    ...PricingWrapper
                    ...ContentListing
                }
            }
            bgHex
            bgImage {
                public_id
                width
                height
                format
            }
            bgPosition
            bgSize
            bgRepeat
        }
        mainNav: contentfulMenu(name: { eq: "Main Nav" }, node_locale: { eq: $locale }) {
            ...MainMenu
        }
        footerNav: contentfulMenu(name: { eq: "Footer Nav" }, node_locale: { eq: $locale }) {
            ...FooterNav
        }
        footerSocials: contentfulMenu(name: { eq: "Footer Socials" }, node_locale: { eq: $locale }) {
            ...FooterSocials
        }
        footerSubMenu: contentfulMenu(name: { eq: "Footer Sub Menu" }, node_locale: { eq: $locale }) {
            ...FooterSubMenu
        }
    }
`;

const moduleMap = {
    ContentfulDuplex: Duplex,
    ContentfulFullWidth: FullWidth,
    ContentfulHero: Hero,
    ContentfulLeftRight: LeftRight,
    ContentfulMultiItem: MultiItem,
    ContentfulPricingWrapper: PricingWrapper,
    ContentfulContentListing: ContentListing,
};

export default function page({ data }) {
    const page = data.contentfulPage;

    const footerData = {
        footerNav: data.footerNav,
        footerSocials: data.footerSocials,
        footerSubMenu: data.footerSubMenu,
    };

    const headerData = {
        mainNav: data.mainNav,
    };

    const metaValues = page.metaFields ?? {};

    // Map modules for placement in the page's primary content area
    const modules = page.modules?.map((el, i) => {
        let Module = moduleMap[el.__typename];

        // Notification Bar exception: passes data to the Layout component
        if (el.__typename === "ContentfulNotificationBar") {
            page.notificationBar = <Module key={i} {...el} />;
            return null;
        } else {
            // Otherwise, return the Module's component for in-page placement
            return <Module moduleId={`module-${i + 1}`} key={i} {...el} />;
        }
    });

    // Build BG styles object to pass to Layout component
    const styleProps = {
        bgColor: page.bgColor,
        bgGradient: page.bgGradient,
        bgHex: page.bgHex,
        bgImage: page.bgImage,
        bgPosition: page.bgPosition,
        bgSize: page.bgSize,
        bgRepeat: page.bgRepeat,
    };

    // Convert (camelize) the navTheme string value into a className. Otherwise defaults to "darkOpaque"
    const bodyHeaderClass =
        data.contentfulPage && data.contentfulPage.navTheme ? camelcase(data.contentfulPage.navTheme) : "darkOpaque";

    return (
        <Layout
            headerMenu={headerData}
            footerMenu={footerData}
            hasMinimalNav={null}
            notificationBar={page.notificationBar}
            pageStyleProps={styleProps}
        >
            <Seo
                title={metaValues.metaTitle || page.title}
                pathname={page.slug}
                description={metaValues.metaDescription}
                canonicalUrl={metaValues.canonicalUrl}
                canonicalUrlOverride={metaValues.canonicalUrlOverride}
                robots={metaValues.metaRobot}
                bingBot={metaValues.bingBot}
                googleBot={metaValues.googleBot}
                image={metaValues.metaImage}
                modifiedTime={page.updatedAt}
                createdTime={page.createdAt}
                languages={data.languages}
            />
            {data.contentfulPage && <Helmet bodyAttributes={{ class: bodyHeaderClass }} />}
            {modules}
        </Layout>
    );
}
