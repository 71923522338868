import React from "react";
import formatContentfulRichText from "@v4/stitch/src/utils/formatContentfulRichText";
import classNames from "classnames/bind";
import CTA from "../../cta/Cta";
import BaseHero from "@v4/gatsby-theme-talend/src/components/modules/hero/Hero";
import loadable from "@loadable/component";

import * as heroStyles from "./hero.module.css";
const cx = classNames.bind(heroStyles);

const Hero = (props) => {
    // Prep-work for custom media components, such as animated hero media. Loadable components uses a relative path,
    // which is why this logic is in this component instead of in the v4 theme's Hero.js component.
    // Get and parse JSON data from props:
    let data = props.data?.internal?.content;
    data = data ? JSON.parse(data) : {};

    // Determine if the hero is meant to use a custom component
    const customMediaId = data?.customMediaId ?? false;

    // Define custom component via the "loadable" library, with SSR enabled
    const CustomMediaComponent = customMediaId ? loadable(() => import(`./${customMediaId}`)) : false;

    return (
        <BaseHero
            CTA={CTA}
            formatContentfulRichText={formatContentfulRichText}
            cx={cx}
            {...props}
            CustomMediaComponent={CustomMediaComponent}
        />
    );
};

export default Hero;
