import React from "react";
import BaseSubCard from "@v4/gatsby-theme-talend/src/components/modules/multiItem/SubCard";
import formatContentfulRichText from "@v4/stitch/src/utils/formatContentfulRichText";
import classNames from "classnames/bind";
import * as cardStyles from "./subCard.module.css";

const cx = classNames.bind(cardStyles);

const SubCard = (props) => <BaseSubCard cx={cx} formatContentfulRichText={formatContentfulRichText} {...props} />;

export default SubCard;
