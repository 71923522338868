// extracted by mini-css-extract-plugin
export var active = "pricingPackage-module--active--6e645";
export var annual = "pricingPackage-module--annual--e4dfe";
export var bottomMessage = "pricingPackage-module--bottomMessage--7dea8";
export var disabledFeatures = "pricingPackage-module--disabledFeatures--39484";
export var featuresToggle = "pricingPackage-module--featuresToggle--21634";
export var grey = "pricingPackage-module--grey--0d830";
export var monthly = "pricingPackage-module--monthly--7863f";
export var monthlyPrice = "pricingPackage-module--monthlyPrice--13745";
export var packageCTAs = "pricingPackage-module--packageCTAs--463cc";
export var packageDescription = "pricingPackage-module--packageDescription--3ef1e";
export var packageFeatures = "pricingPackage-module--packageFeatures--c9191";
export var packageFeaturesWrapper = "pricingPackage-module--packageFeaturesWrapper--804a1";
export var packageTitle = "pricingPackage-module--packageTitle--a067a";
export var pricingPackage = "pricingPackage-module--pricingPackage--15eb2";
export var pricingSlider = "pricingPackage-module--pricingSlider--b25b7";
export var pricingSliderInput = "pricingPackage-module--pricingSliderInput--f8891";
export var pricingTopGroup = "pricingPackage-module--pricingTopGroup--86f90";
export var sliderRows = "pricingPackage-module--sliderRows--ab1ad";
export var toggleCheckbox = "pricingPackage-module--toggleCheckbox--174e4";
export var toggleContainer = "pricingPackage-module--toggleContainer--0d2b3";
export var toggleInterval = "pricingPackage-module--toggleInterval--640ab";
export var toggleLabel = "pricingPackage-module--toggleLabel--16f1e";
export var toggleRound = "pricingPackage-module--toggleRound--ad748";
export var toggleSwitch = "pricingPackage-module--toggleSwitch--bc866";
export var toggleTopLabel = "pricingPackage-module--toggleTopLabel--963be";
export var topMessage = "pricingPackage-module--topMessage--6aaf4";