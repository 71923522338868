import React from "react";
import formatContentfulRichText from "@v4/stitch/src/utils/formatContentfulRichText";
import classNames from "classnames/bind";
import CTA from "../../cta/Cta";
import BaseFullWidth from "@v4/gatsby-theme-talend/src/components/modules/fullWidth/FullWidth";

import * as styles from "./fullWidth.module.css";
const cx = classNames.bind(styles);

const FullWidth = (props) => (
    <BaseFullWidth CTA={CTA} formatContentfulRichText={formatContentfulRichText} cx={cx} {...props} />
);

export default FullWidth;
