import React from "react";
import formatContentfulRichText from "@v4/stitch/src/utils/formatContentfulRichText";
import classNames from "classnames/bind";
import CTA from "../../cta/Cta";
import BaseLeftRight from "@v4/gatsby-theme-talend/src/components/modules/leftRight/LeftRight";

import * as styles from "./leftRight.module.css";
const cx = classNames.bind(styles);

const LeftRight = (props) => (
    <BaseLeftRight CTA={CTA} formatContentfulRichText={formatContentfulRichText} cx={cx} {...props} />
);

export default LeftRight;
