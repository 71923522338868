import React, { useState, useRef } from "react";
import classNames from "classnames/bind";
import Cta from "../../cta/Cta";
import isDesktopQuery from "@v4/utils/mediaQueries";
import { useMediaQuery } from "react-responsive";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import formatContentfulRichText from "@v4/stitch/src/utils/formatContentfulRichText";
import scrollWithHeaderOffset from "@v4/utils/scrollWithHeaderOffset";
import * as packageStyles from "./pricingPackage.module.css";

// Symbols for accordion elements
import MinusSVG from "@v4/gatsby-theme-talend/src/assets/svg/minus-symbol--circle.inline.svg";
import PlusSVG from "@v4/gatsby-theme-talend/src/assets/svg/plus-symbol--circle.inline.svg";

const cx = classNames.bind(packageStyles);

const PricingPackage = (props) => {
    // Ref for the package element
    const packageRef = useRef();

    // State var for the "monthly/annual" toggle
    const [isAnnual, setIsAnnual] = useState(false);

    // Variable to determine if slider is present on the package
    const hasRowSlider = props.variations?.includes("Has Rows Slider");

    // Variable to determine if "monthly/annual" toggle is present on the package
    const hasBillingToggle = props.variations?.includes("Has Billing-Cycle Toggle");

    // Media query for desktop
    const isDesktop = useMediaQuery(isDesktopQuery, undefined);

    // Possible array of billing cycle "stops" for the slider, delivered via JSON from a "data" field/prop
    let rowsArray = hasRowSlider && props.data?.internal?.content ? JSON.parse(props.data.internal.content) : null;
    rowsArray = rowsArray && rowsArray.slider;

    // Child component used to house the slider component
    const SliderUI = ({ sliderValue, setSliderValue }) => {
        // Handle the change of the input field (slider)
        const handleChange = (e) => {
            setSliderValue(e.target.value);
        };

        return (
            <>
                <p>
                    <label htmlFor="pricingSlider">
                        <strong>Choose rows/</strong>month (in millions):
                    </label>
                </p>
                <div className={cx("pricingSlider")}>
                    <input
                        id="pricingSlider"
                        className={cx("pricingSliderInput")}
                        type="range"
                        min={0}
                        max={rowsArray.length - 1}
                        step={1}
                        value={sliderValue}
                        onChange={handleChange}
                    />
                    <ul className={cx("sliderRows")}>
                        {rowsArray && rowsArray.map((row, index) => <li key={index}>{row.rows}</li>)}
                    </ul>
                </div>
            </>
        );
    };

    // Child component for the Monthly/Annual toggle
    const ToggleUI = ({ isAnnual, setIsAnnual }) => {
        const handleChange = (e) => {
            e.preventDefault();
            setIsAnnual(!isAnnual);
        };

        const monthlyIsActive = isAnnual ? "" : "active";
        const annualIsActive = isAnnual ? "active" : "";

        return (
            <div className={cx("toggleContainer")}>
                <div className={cx("toggleTopLabel")}>Billing Cycle:</div>
                <form>
                    <label className={cx("toggleLabel")} htmlFor="toggleCheckbox">
                        <span className={cx("toggleInterval", "monthly", monthlyIsActive)}>Monthly</span>
                        <input
                            className={cx("toggleCheckbox")}
                            id="toggleCheckbox"
                            type="checkbox"
                            onChange={handleChange}
                        />
                        <div className={cx("toggleSwitch", annualIsActive)}>
                            <button
                                className={cx("toggleRound", annualIsActive)}
                                onClick={(e) => handleChange(e)} // "Spacebar" keypress (acts as a click)
                                onChange={(e) => handleChange(e)} // "Enter" keypress
                            >
                                Toggle button
                            </button>
                        </div>
                        <span className={cx("toggleInterval", "annual", annualIsActive)}>Annual</span>
                    </label>
                </form>
            </div>
        );
    };

    // A parent component which uses both the <SliderUI /> and <ToggleUI /> components:
    const PriceUI = ({ price, isAnnual, setIsAnnual }) => {
        // State vars to handle the value of the input fields
        const [sliderValue, setSliderValue] = useState(0);

        // Splitting the monthly and annual billing price values
        const monthlyPrices = rowsArray ? rowsArray.map((row) => row.monthlyPrice) : null;
        const annualPrices = rowsArray ? rowsArray.map((row) => row.annualPrice) : null;

        if (!price && rowsArray) {
            price = isAnnual ? annualPrices[sliderValue] : monthlyPrices[sliderValue];
        }

        // Create our number formatter.
        const priceFormatter = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
        });

        price = priceFormatter.format(price);

        return (
            <>
                {price && (
                    <div className={cx("monthlyPrice")}>
                        {hasBillingToggle ? "Starts at" : "Monthly at*"}
                        <br />
                        <span>{price}</span>
                    </div>
                )}
                {rowsArray && <SliderUI sliderValue={sliderValue} setSliderValue={setSliderValue} />}
                {rowsArray && hasBillingToggle && <ToggleUI isAnnual={isAnnual} setIsAnnual={setIsAnnual} />}
            </>
        );
    };

    // Accordion Feature
    const featuresWrapperRef = React.createRef();
    const [featuresOpen, setFeaturesOpen] = useState(false);

    // Accordion Function
    const featuresAccordion = () => {
        // Get the wrapper element
        const featWrapEl = featuresWrapperRef.current;

        // Get the full height of the features <ul>
        const fullHeight = `${featWrapEl.firstChild.scrollHeight}px`;

        // Set the initial height for the CSS transition
        featWrapEl.style.height = fullHeight;

        // Scroll the features into view with a -20px offset
        scrollWithHeaderOffset(featuresWrapperRef.current, 500, -20);

        // If the features are open, close them
        if (featuresOpen) {
            // Closing: At the next frame, reset the height
            requestAnimationFrame(() => {
                featWrapEl.style.height = null;

                // Scroll to the corresponding package with a -20px offset
                scrollWithHeaderOffset(packageRef.current, 500, -20);
            });
        }

        // Update state to match the Accordion
        setFeaturesOpen(!featuresOpen);
    };

    const TopMessage = ({ topMessage, topMessageCondition, isAnnual }) => {
        const renderCondition = !topMessageCondition ? "Annually" : topMessageCondition;
        /** 1. If we have a top message, render it
            2. If we have a top message condition - isAnnual == true and topMessageCondition == "Both" or "Annually" render it
            3. If we have a top message condition - isAnnual == false and topMessageCondition == "Both" or "Monthly" render it */
        if (
            topMessage &&
            (renderCondition === "Both" ||
                (renderCondition === "Annually" && isAnnual) ||
                (renderCondition === "Monthly" && !isAnnual))
        ) {
            return <div className={`${cx("topMessage")} topMessage`}>{topMessage}</div>;
        } else return null;
    };

    return (
        <div className={`${cx("pricingPackage")} pricingPackage`} ref={packageRef}>
            <TopMessage
                topMessage={props.topMessage}
                topMessageCondition={props.topMessageCondition}
                isAnnual={isAnnual}
            />
            <div className={`${cx("pricingTopGroup")} pricingTopGroup`}>
                <div>
                    {props.title && (
                        <div className={`${cx("packageTitle")} packageTitle`}>
                            {renderRichText(props.title, formatContentfulRichText())}
                        </div>
                    )}
                    {props.description && (
                        <div className={`${cx("packageDescription")} packageDescription`}>
                            {renderRichText(props.description, formatContentfulRichText())}
                        </div>
                    )}
                    <PriceUI price={props.monthlyPrice} isAnnual={isAnnual} setIsAnnual={setIsAnnual} />
                </div>
                <div>
                    {props.bottomMessage && <div className={cx("bottomMessage")}>{props.bottomMessage}</div>}
                    {props.ctas && (
                        <div className={cx("packageCTAs")}>
                            {props.ctas.map((cta) => (
                                <Cta key={cta.id} {...cta} customClass={"pricingCTA"} />
                            ))}
                        </div>
                    )}
                </div>
            </div>
            {(props.enabledFeatures || props.disabledFeatures) && (
                <>
                    <div className={cx("packageFeaturesWrapper")} ref={featuresWrapperRef}>
                        <div>
                            {props.enabledFeatures && (
                                <div className={cx("packageFeatures", "enabledFeatures")}>
                                    {renderRichText(props.enabledFeatures, formatContentfulRichText())}
                                </div>
                            )}
                            {props.disabledFeatures && (
                                <div className={cx("packageFeatures", "disabledFeatures")}>
                                    {renderRichText(props.disabledFeatures, formatContentfulRichText())}
                                </div>
                            )}
                        </div>
                    </div>
                    <button
                        className={cx("featuresToggle")}
                        onClick={(e) => {
                            if (isDesktop === false) {
                                featuresAccordion();
                            }
                        }}
                    >
                        {featuresOpen ? "hide features" : "show features"}
                        {featuresOpen ? <MinusSVG></MinusSVG> : <PlusSVG></PlusSVG>}
                    </button>
                </>
            )}
        </div>
    );
};

export default PricingPackage;
