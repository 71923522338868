import React from "react";
import { useI18n } from "@v4/utils/i18nContext";
import {
    formValidation,
    destyleMktoForm,
    formExperimentFill,
    zoomInfoIntegration,
    applyCheckboxClasses,
    fetchCheckboxText,
    addPurserValuesToMarketoForms,
    trustAssessor,
    stitchSignUp,
    normalFormSubmission,
} from "../utils/form-functions";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { formValidationMessages } from "../translations/formValidationMessages";
import formatContentfulRichText from "@v4/utils/formatContentfulRichText";
import { useMarketoForm } from "../hooks";

const MarketoForm = ({
    MicroBanner,
    formHeader,
    formId,
    buttonLabel,
    formCallback,
    formThankYouMessage,
    formConversionType,
    disableOpaqueBackground,
    promoBanners,
}) => {
    const { i18n } = useI18n();
    const allValidationMessages = formValidationMessages[i18n.curr.langCode];

    useMarketoForm({
        formId,
        callback: () =>
            callBacks(formCallback, allValidationMessages, formThankYouMessage, formConversionType, buttonLabel),
    });

    const customBgClass =
        disableOpaqueBackground && disableOpaqueBackground[0] === "True" ? "bg-transparent" : "bg-solid";

    const header =
        formHeader && formHeader.raw ? (
            <div className="formHeader">{renderRichText(formHeader, formatContentfulRichText())}</div>
        ) : null;

    return (
        <>
            <div className={`formHolder form-id-${formId} ${customBgClass}`}>
                {header}
                <form id={`mktoForm_${formId}`}>
                    {/* Span element is present so Optimizely can manipulate submit label before form loads */}
                    <span className="hidden mktoButton mktoButtonInitEl">{buttonLabel}</span>
                </form>
            </div>
            {promoBanners.length > 0 && (
                <div className={`formTYComponents`}>
                    {promoBanners.map((banner) => {
                        // Need to update the CTA text color if it is the Light banner variant
                        // It is easier to add a variant here and the supporting css styles in
                        // the microBanner.module.css file
                        if (banner.variant === "Light") {
                            banner.variant = "AltLight";
                        }

                        return <MicroBanner key={banner.id} item={banner} />;
                    })}
                </div>
            )}
        </>
    );
};

const callBacks = (callback, validationMessages, formThankYouMessage, formConversionType, buttonLabel) => {
    const thankYou = formThankYouMessage ? formThankYouMessage : validationMessages.thank_you_text;

    const coversionMap = {
        "None Set": "none",
        "Demo Request": "demo",
        "Free Trial Signup": "trial",
        "Content Download": "content",
        "Webinar Signup": "webinar",
        "Contact Sales": "contact",
        TOS: "tos",
    };

    const conversionType = coversionMap[formConversionType];

    if (typeof window !== "undefined" && window.MktoForms2) {
        // eslint-disable-next-line no-undef
        MktoForms2.whenRendered((form) => {
            destyleMktoForm(form);

            // Get the button label from the hidden span element, which may have received modification from Optimizely
            const initButtonEl = document.querySelector(".mktoButtonInitEl");

            // Replace form submit label with custom label from span element
            if (buttonLabel) {
                form.getFormElem()
                    .find("button.mktoButton")
                    .html(initButtonEl?.innerText);
            }
        });

        MktoForms2.whenReady((form) => {
            // Adding custom styles to field wrapper elements when checkbox is present
            let formEl = form.getFormElem()[0];
            formEl.addEventListener("change", applyCheckboxClasses);
            formEl.addEventListener("change", fetchCheckboxText);
            applyCheckboxClasses();
            fetchCheckboxText();
            formExperimentFill();

            // Initialize the ZoomInfo integration
            zoomInfoIntegration.init(form);

            // Add Values from the Purser to the forms
            addPurserValuesToMarketoForms(form);

            // Initialize the form validation
            formValidation.init(validationMessages);

            // Various Form Callbacks goes here
            if (callback === "Trust Assessor") {
                trustAssessor(form);
            }

            if (callback === "Stitch Sign Up") {
                stitchSignUp(form);
            }

            if (callback === "Normal") {
                normalFormSubmission(form, thankYou, conversionType);
            }
        });
    }
};

export default MarketoForm;
