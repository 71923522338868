import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import FilterGroup from "../../filters/FilterGroup";
import { commonTranslations } from "@v4/gatsby-theme-talend/src/translations/common";
import { Resource } from "./Resource";
import ResourceFeaturedCard from "./ResourceFeaturedCard";
import BaseResourceListing from "@v4/gatsby-theme-talend/src/components/listings/resources/ResourceListing";
import * as listingStyles from "./resourceListing.module.css";
import classnames from "classnames/bind";

const cx = classnames.bind(listingStyles);

// A filterable listing of all resources in the current language. Featured items are shown as
// cards along the top, while "tier 1" items show up at the top of the rest of the resources
// in the listing.
const ResourceListing = (props) => {
    const data = useStaticQuery(graphql`
        query ResourcesQuery {
            # Filter out all resources that has the hideFromListing box checked
            resources: allContentfulResource(filter: { hideFromListing: { ne: "Yes" } }) {
                nodes {
                    __typename
                    ... on Node {
                        ...Resource
                    }
                }
            }
            resourceTaxonomy: allContentfulTaxonomy(filter: { name: { eq: "Resources" } }) {
                nodes {
                    name
                    node_locale
                    terms {
                        ...TermFields
                        ...SubTermFields
                    }
                }
            }
        }
    `);

    // TODO: replace this with a `languages` field on the `resource` content type when languages are added to stitch.
    props.tierOneItems.forEach((el) => (el.languages = "All"));
    data.resources.nodes.forEach((el) => (el.languages = "All"));

    // Change value for commonTranslations[locale].viewMore to "Show More" and add to new variable called customTranslations
    commonTranslations["en"].viewMore = "Show More";
    commonTranslations["en"].viewLess = "Show Less";

    return (
        <BaseResourceListing
            cx={cx}
            ResourceFeaturedCard={ResourceFeaturedCard}
            Resource={Resource}
            FilterGroup={FilterGroup}
            commonTranslations={commonTranslations}
            data={data}
            showNumResults={true}
            {...props}
        />
    );
};

export default ResourceListing;
