export const formValidationMessages = {
    en: {
        firstname_error: "First Name must not include symbols or numbers.",
        lastname_error: "Last Name must not include symbols or numbers.",
        phone_error: "Phone number is invalid. Must be 8 to 20 digits.",
        thank_you_text: "Thank you for reaching out to us. We will be in touch!",
    },
    "en-GB": {
        firstname_error: "First Name must not include symbols or numbers.",
        lastname_error: "Last Name must not include symbols or numbers.",
        phone_error: "Phone number is invalid. Must be 8 to 20 digits.",
        thank_you_text: "Thank you for reaching out to us. We will be in touch!",
    },
    fr: {
        firstname_error: "Le prénom ne doit pas inclure de symboles ou de numéros.",
        lastname_error: "Le nom de famille ne doit pas inclure de symboles ou de numéros.",
        phone_error: "Le numéro de téléphone n'est pas valide. Doit être de 8 à 20 chiffres.",
        thank_you_text: "Merci de nous avoir contactés. Nous reviendrons vers vous bientôt !",
    },
    de: {
        firstname_error: "Der Vorname darf keine Symbole oder Zahlen enthalten.",
        lastname_error: "Der Nachname darf keine Symbole oder Zahlen enthalten.",
        phone_error: "Die Telefonnummer ist ungültig. Muss 8 bis 20 Ziffern haben.",
        thank_you_text: "Vielen Dank, dass Sie uns kontaktiert haben. Wir werden uns in Kürze bei Ihnen melden!",
    },
    it: {
        firstname_error: "Il nome non deve includere simboli o numeri.",
        lastname_error: "Cognome non deve includere simboli o numeri.",
        phone_error: "Il numero di telefono non è valido. Deve essere di 8-20 cifre.",
        thank_you_text: "Grazie per il tuo interesse. Restiamo in contatto!",
    },
    ja: {
        firstname_error: "名に記号または数字を含めることはできません。",
        lastname_error: "姓には、記号または数字を含めることはできません。",
        phone_error: "電話番号が無効です。8 桁から 20 桁でなければなりません。",
        thank_you_text: "お問い合わせいただきありがとうございます。後ほど弊社よりご連絡差し上げます。!",
    },
};
