import React from "react";
import BaseMultiItem from "@v4/gatsby-theme-talend/src/components/modules/multiItem/MultiItem";
import classNames from "classnames/bind";
import SubAccordion from "./SubAccordion";
import SubCard from "./SubCard";
import SubImage from "./SubImage";
import CustomerCard from "./CustomerCard";
import SubIcon from "./SubIcon";
import SubRichText from "./SubRichText";
import CTA from "@v4/stitch/src/components/cta/Cta";

import formatContentfulRichText from "@v4/stitch/src/utils/formatContentfulRichText";

import * as multiItemStyles from "./multiItem.module.css";
const cx = classNames.bind(multiItemStyles);

const itemType = (item) =>
    ({
        ContentfulSubAccordion: <SubAccordion item={item} />,
        ContentfulSubCard: <SubCard item={item} />,
        ContentfulSubImage: <SubImage item={item} />,
        ContentfulCustomerCard: <CustomerCard item={item} />,
        ContentfulSubIcon: <SubIcon item={item} />,
        ContentfulSubRichText: <SubRichText item={item} />,
    }[item.__typename]);

const MultiItem = (props) => (
    <BaseMultiItem
        cx={cx}
        itemType={itemType}
        formatContentfulRichText={formatContentfulRichText}
        CTA={CTA}
        {...props}
    />
);

export default MultiItem;
