import React from "react";
import formatContentfulRichText from "@v4/stitch/src/utils/formatContentfulRichText";
import classNames from "classnames/bind";
import MicroRich from "../../microRich/MicroRich";
import MarketoForm from "../../marketoForm/MarketoForm";
import MicroImage from "@v4/gatsby-theme-talend/src/components/MicroImage/MicroImage";
import MicroVideo from "@v4/gatsby-theme-talend/src/components/MicroVideo/MicroVideo";

import { Duplex as BaseDuplex } from "@v4/gatsby-theme-talend/src/components/modules/duplex/Duplex";
import * as DuplexStyles from "./duplex.module.css";

const cx = classNames.bind(DuplexStyles);

export const itemType = (item) =>
    ({
        ContentfulMicroRichText: <MicroRich item={item} />,
        ContentfulMicroImage: <MicroImage item={item} />,
        ContentfulMicroVideo: <MicroVideo item={item} />,
        ContentfulMarketoForm: <MarketoForm {...item} />,
    }[item.__typename]);

const Duplex = (props) => (
    <BaseDuplex formatContentfulRichText={formatContentfulRichText} cx={cx} itemType={itemType} {...props} />
);

export default Duplex;
